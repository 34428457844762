import React from 'react'
import { subMenu } from '../../config/site-content'
import { replaceWhitespaceWithDash } from '../../utils';
import { Link } from 'gatsby';
import { useMain } from '../../context/provider';

export const NavMenus = () => {
   const { handleClick } = useMain()

   const subItems = replaceWhitespaceWithDash(subMenu);

   return (
      <div className="w-64 bg-white p-6 shadow-xl">
         <div className="mb-3 space-y-3">
            <h3 className="font-semibold">For Individuals</h3>
            {subItems.map((item, index) => (
               <Link to={index === 1 ? '/membros/' : '/' + item.toLowerCase()} className="block text-sm hover:underline" key={`submenu-${index}`} onClick={handleClick}>
                  {item}
               </Link>
            ))}
         </div>

         <button className="w-full rounded-lg border-2 border-neutral-950 px-4 py-2 font-semibold transition-colors hover:bg-neutral-950 hover:text-white">
            Contact sales
         </button>
      </div>
   );
}
