import { useEffect, useRef, useState } from "react";
import axios from "axios";

// Generate a random user ID
const generateUserId = () => {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
};

export const useVisitorTracker = () => {
   const [templateId, setTemplateId] = useState<string | null>(null);
   const [clickCount, setClickCount] = useState<number>(0);
   const [browserInfo, setBrowserInfo] = useState<string>('');
   const hasSentVisitData = useRef(false);
   const [pages, setPages] = useState<string[]>([]);

   useEffect(() => {
      if (typeof window !== 'undefined') {
         const storedCount = localStorage.getItem('clickCount');
         const storedPages = localStorage.getItem('pages');
         const storedTemplateId = localStorage.getItem('compunyId');
         const currentBrowserInfo = navigator.userAgent;

         if (storedCount) {
            setClickCount(parseInt(storedCount, 10));
         }

         if (storedPages) {
            setPages(JSON.parse(storedPages));
         }

         if (!storedTemplateId) {
            const newTemplateId = generateUserId();
            localStorage.setItem('compunyId', newTemplateId);
            setTemplateId(newTemplateId);
         } else {
            setTemplateId(storedTemplateId);
         }

         setBrowserInfo(currentBrowserInfo);
      }
   }, []);

   useEffect(() => {
      if (templateId && !hasSentVisitData.current) {
         sendVisitData(templateId, browserInfo);
         hasSentVisitData.current = true;
      }
   }, [templateId, browserInfo]);

   useEffect(() => {
      if (templateId && clickCount > 0) {
         sendVisitData(templateId, browserInfo);
      }
   }, [clickCount, templateId]);

   const trackPage = (path: string) => {
      setPages(prevPages => {
         if (!prevPages.includes(path)) {
            const newPages = [...prevPages, path];
            if (typeof window !== 'undefined') {
               localStorage.setItem('pages', JSON.stringify(newPages));
            }
            return newPages;
         }
         return prevPages;
      });
   };

   const sendVisitData = async (id: string, userBrowser: string) => {
      const data = {
         views: [
            {
               viewerId: id,
               pages,
               clicks: clickCount,
               browser: userBrowser,
            }
         ]
      };

      try {
         await axios.post('https://api.mockfly.dev/mocks/339d96f2-6c3d-43f4-a01e-8f48385441e7/template', data);
      } catch (error) {
         console.error('Error sending analytics data', error);
      }
   };

   const handleClick = () => {
      setClickCount(prev => {
         const newCount = prev + 1;
         if (typeof window !== 'undefined') {
            localStorage.setItem('clickCount', newCount.toString());
         }
         return newCount;
      });
   };

   return { handleClick, clickCount, trackPage };
};
