import React from 'react'
import { Link } from 'gatsby'
import { footerBg } from '../images'
import { Facebook, Instagram, Twitter, Youtube } from 'lucide-react'
import { APIDATA, LOCAL_DATA } from '../config/site-content'
import Button from './ui/button'
import { IContact, IFooter } from './types'
import { useVisitorTracker } from '../hooks/use-tracker-visiters'

const date = new Date()
const year = date.getFullYear()
const Footer = () => {
   const { handleClick } = useVisitorTracker()
   const data = (APIDATA.length === 0 ? LOCAL_DATA.footer : {}) as IFooter;
   const mediaUrl = (APIDATA && APIDATA.length > 0 ? APIDATA : LOCAL_DATA.contact) as IContact;
   const mediaIcon = [
      { url: mediaUrl.facebook, icon: <Facebook /> },
      { url: mediaUrl.twitter, icon: <Twitter /> },
      { url: mediaUrl.instagram, icon: <Instagram /> },
      { url: mediaUrl.youtube, icon: <Youtube /> },
   ]
   return (
      <footer className='relative bg-cover bg-center p-[20px] lg:p-[35px] footer' style={{ backgroundImage: `url(${footerBg})` }}>
         <div className="flex flex-col items-center sm:flex-row">
            <div className='relative w-full sm:w-[25%] flex-1 text-white p-[30px] text-center md:text-left space-y-4'>
               {data.logo ? (
                  <Link to='/' onClick={handleClick}>
                     <img src={data.logo} className='w-24 h-24' width={200} height={200} alt="logo" />
                  </Link>
               ) : (
                  <h2>Logo</h2>
               )}
               <p>{data.descrition}</p>
            </div>
            <div className='relative w-full lg:w-[25%] flex-1 text-white p-[30px] space-y-4'>
               <h2 className='text-base text-white'>{data.event}</h2>
               {data.events.map((item, index) => (
                  <div className='mb-4' key={index}>
                     <span className='text-gray-400'>19-August-2021</span>
                     <Link to={`/eventos/${item.id}`} onClick={handleClick}>
                        <h3 className='text-sm transition-colors hover:text-primmary'>{item.title}</h3>
                     </Link>
                  </div>
               ))}
            </div>
            <div className='relative w-full lg:w-[25%] flex-1 text-white p-[30px] space-y-4'>
               <h2 className='text-base text-white'>NEWSLETTER</h2>
               <p>Subscreva-se a nossa newsletter e fique por dentro de todas novidades</p>
               <div className='space-y-2'>
                  <input type="text" className='w-full p-3 text-black rounded-md focus-within:outline-none' placeholder='Escreva seu email' />
                  <Button type='button' className='w-full transition-colors hover:bg-orange-600'>Subscrever</Button>
               </div>
            </div>
         </div>
         <div className="relative text-white flex items-center justify-center gap-4">
            {mediaIcon.map((icon, index) => (
               <a href={icon.url} key={index} onClick={handleClick}>
                  <i className='bg-[#ffffff42] w-9 h-9 rounded-full flex items-center justify-center transition-colors hover:bg-orange-400'>
                     {icon.icon}
                  </i>
               </a>
            ))}
         </div>
         <hr className='w-full bg-[#ffffff42] m-4' />
         <div className='relative px-[20px] md:px-[50px] lg:px-[100px] text-center text-white'>
            <p>© {year} <a href="https://ahoura.ao/">Ahoura.</a></p>
         </div>
      </footer>
   )
}

export default Footer