import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

type TRootElement = {
   children: ReactNode;
}
const queryClient = new QueryClient();

const RootElement = ({ children }: TRootElement) => {
   return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default RootElement;